<!-- 生产分析-停机分析 -->
<template>
  <div class="page_container" v-loading="loading">
    <div class="box_head1">停机分析</div>
    <div class="flex flex_column bgc_FFFFFF">
      <!-- select下拉框 -->
      <div class="flex justify_between alarmAnalysisMargin fn_size16">
        <div>
          <!-- <el-select v-model="value" placeholder="公司选择" class="mg_right_10 width_120">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="value" placeholder="车间选择" class="mg_right_10 width_120">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select> -->
          <!-- <el-select v-model="value" placeholder="请选择组织" class="mg_right_10 width_120">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select> -->
          <el-cascader v-model="organizationValue" placeholder="请选择组织维度" :options="organizationSelect"
            ref="demoCascader" :props="defaultProps" class="mg_right_10 " :show-all-levels="false"
            collapse-tags @change="handleChangeCascader" clearable>
            <!-- width_190 -->
          </el-cascader>
          <el-select v-model="equipmentUseValue" placeholder="设备用途" class="mg_right_10 width_190" multiple
            collapse-tags>
            <el-option v-for="item in equipmentUse" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <el-select v-model="importanceListValue" placeholder="重要度" class="mg_right_10 width_190" multiple
            collapse-tags>
            <el-option v-for="item in importanceList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <!-- <el-select v-model="value" placeholder="供应商" class="mg_right_10 width_120">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="value" placeholder="系统商" class="mg_right_10 width_120">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="value" placeholder="外委单位" class="mg_right_10 width_120">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select> -->
          <el-date-picker v-model="dataTime" type="datetime" placeholder="选择日期时间" class="mg_right_10">
          </el-date-picker>
          <!-- <el-select v-model="value" placeholder="设备编码" class="mg_right_10 width_120">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select> -->
          <!-- <el-select v-model="value" placeholder="查询时间" class="mg_right_10" style="width: 160px">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select> -->
          <el-button type="primary" class="width_110" style="background: #009bfd; border: none" @click="query">查询
          </el-button>
        </div>
        <!-- <div>
          <el-button type="primary" class="width_110" style="background: #009bfd; border: none">导出</el-button>
          <el-button type="primary" class="width_30" style="background: #009bfd; border: none">
            <img src="../../assets/images/navigation/zs_icon10.png" alt="" />
          </el-button>
        </div> -->

      </div>
      <!-- table表格 -->
      <div class="alarmAnalysisMargin mg_right_22">
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" height="500" border>
          <el-table-column type="index" label="序号" width="60"></el-table-column>
          <el-table-column prop="equipmentID" label="设备编号" width="" show-overflow-tooltip></el-table-column>
          <el-table-column prop="startTime" label="停机开始时间" width="155" :formatter="formatDate">
          </el-table-column>
          <el-table-column prop="endTime" label="停机结束时间" width="155" :formatter="formatDate">
          </el-table-column>
          <el-table-column prop="stopTime" label="持续时间(min)" width=""></el-table-column>
          <el-table-column prop="stopCategoryName" label="停机原因大类" width=""></el-table-column>
          <el-table-column prop="stopTypeName" label="停机极原因类别" width=""></el-table-column>
          <el-table-column prop="equipmentName" label="设备名称" width=""></el-table-column>
          <el-table-column prop="companyName" label="所属公司" width=""></el-table-column>
          <el-table-column prop="workShopName" label="所属车间" width=""></el-table-column>
          <el-table-column prop="equipmentcategoryName" width="" label="设备用途" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="equipmentlevelName" width="" label="重要程度" show-overflow-tooltip></el-table-column>
          <!-- <el-table-column prop="" label="生产厂商" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="" label="系统厂商" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="" label="外委单位" width="120" show-overflow-tooltip></el-table-column> -->
          <el-table-column prop="" label="备注" width="" show-overflow-tooltip></el-table-column>
        </el-table>
        <div class="flex justify_between align_center">
          <div class="flex">
            <p class="aaRed">注：</p>
            <p>所有Kpi指标都可配置阈值提醒</p>
            <p>阈值设置可分为两级：警示和危险;</p>
            <p class="aaOrange">超出警示值，黄色指示</p>
            <p class="aaRed">；超出危险值红色指示，并有告知信息</p>
            <p class="aaGreen">；正常位绿色指示；</p>
          </div>

          <!-- <div class="flex justify_between align_start"> -->
          <!-- <div class="color_808080 fn_size14 mg_top_19"></div> -->
          <!-- <el-pagination layout="prev, pager, next" :total="50"></el-pagination> -->
          <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
            :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
          <!-- </div> -->
        </div>
      </div>


      <!-- echarts图 -->
      <div class="diy_div flex alarmAnalysisMargin">
        <div id="chartColumn1" class="height_100 width_100"></div>
        <div id="chartColumn2" class="height_100 width_100"></div>
        <div id="chartColumn3" class="height_100 width_100 mg_right_10"></div>
        <div id="chartColumn4" class="height_100 width_100"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as echarts from "echarts";
  import {
    Loading
  } from 'element-ui';
  import {
    getEquipmentInforStopAnalysisList,
    getEquipmentInforStopAnalysisBarByMonth,
    getEquipmentInforStopAnalysisBarByDay,
    getEquipmentInforStopAnalysisPieChartCountBystandByCause,
    getEquipmentInforStopAnalysisPieChartSumBystandByCause,
    stopAnalysisOrganizationSelect,
    stopAnalysisAlarmLevelSelect,
    stopAnalysisAlarmTypeSelect
  } from "@/api/api_productAnalysis";
  export default {
    data() {
      return {
        BarByDay: {},
        value: "",
        barMonth: {
          xAxis: [],
          yAxis: []
        },
        dataPieCount: {
          data: [],
          total: 0
        },
        dataPieCount1: {
          data: [],
          total: 0
        },
        options: [],
        tableData: [],
        currentPage: 1,
        pageSize: 10,
        pageQuantity: 0,
        organizationValue: [],
        organizationSelect: [],
        defaultProps: {
          label: 'Name',
          value: 'ID',
          expandTrigger: 'hover',
          multiple: true, //多选
          emitPath: false, //只返回选中节点的值
          // checkStrictly: true //多选任一级
        },
        dataTime: new Date(),
        equipmentUseValue: [],
        equipmentUse: [],
        importanceListValue: [],
        importanceList: [],
        currentData: [],
        standardArr: [],
        standardData:[],
        permission: {},
        loading: true
      };
    },

    mounted() {
      if (localStorage.getItem('permission') != null) {
        // console.log(JSON.parse(localStorage.getItem('permission')));
        this.permission = JSON.parse(localStorage.getItem('permission'))
      }
      this.stopAnalysisOrganizationSelect();
      this.stopAnalysisAlarmLevelSelect();
      this.stopAnalysisAlarmTypeSelect();
      // this.getEquipmentInforStopandbyAnalysisList();
      // this.getEquipmentInforStopAnalysisBarByMonth();
      // this.getEquipmentInforStopAnalysisBarByDay();
      // this.getEquipmentInforStopAnalysisPieChartCountBystandByCause();
      // this.getEquipmentInforStopAnalysisPieChartSumBystandByCause()
      // this.drawShape();
    },
    methods: {
      async stopAnalysisOrganizationSelect() {
        // const loading = this.$loading({
        //   lock: true, //lock的修改符--默认是false
        //   // text: 'Loading',//显示在加载图标下方的加载文案
        //   // spinner: 'el-icon-loading',//自定义加载图标类名
        //   // background: 'rgba(0, 0, 0, 0.7)',//遮罩层颜色
        // });
        var param = {
          permission: this.permission
        };
        const selectRes = await stopAnalysisOrganizationSelect(param);
        this.organizationSelect = selectRes.data.Tree;
        this.standardArr = selectRes.data.organizationPermissionAll;
        for (var i = 0; i < this.standardArr.length; i++) {
          if (this.standardArr[i].Type == 'E') {
            // this.organizationValue = this.standardArr[i].ID;
            this.currentData = this.standardArr[i].ID;
            this.standardData = this.standardArr[i].ID;
          }
        }
        this.getEquipmentInforStopandbyAnalysisList();
        this.getEquipmentInforStopAnalysisBarByMonth();
        this.getEquipmentInforStopAnalysisBarByDay();
        this.getEquipmentInforStopAnalysisPieChartCountBystandByCause();
        this.getEquipmentInforStopAnalysisPieChartSumBystandByCause();
        this.loading = false;
        // loading.close()
        // this.organizationValue.push(selectRes.data.organizationPermission[0].ID);
        // console.log(this.organizationSelect)
        // var textData = [];
      },
      // 停机分析-设备类型下拉选
      async stopAnalysisAlarmTypeSelect() {
        var param = {}
        const res = await stopAnalysisAlarmTypeSelect(param);
        if (res && res.data) {
          this.equipmentUse = res.data
        }
      },
      // 停机分析-重要程度下拉选
      async stopAnalysisAlarmLevelSelect() {
        var param = {}
        const res = await stopAnalysisAlarmLevelSelect(param);
        if (res && res.data) {
          this.importanceList = res.data
        }
      },
      handleChangeCascader() {
        this.currentData = this.organizationValue;
        // console.log(this.organizationValue)

        // const obj = this.$refs['demoCascader'].getCheckedNodes()
        // // console.log(obj);
        // // var testDate = [];
        // var currentData = {
        //   Type: [],
        //   ID: []
        // };
        // for (var i = 0; i < obj.length; i++) {
        //   // console.log(obj[i].data)
        //   var authorityadmin = this.standardArr.filter(itmer => itmer.Type == obj[i].data.Type);
        //   // console.log(authorityadmin)
        //   if (authorityadmin.length == 0) {
        //     this.$message({
        //       message: '警告哦，您没有权限选择此项',
        //       type: 'warning'
        //     });
        //     this.organizationValue = []; //清空选项
        //     // this.$refs['demoCascader'].clearCheckedNodes()
        //     return
        //   }
        //   currentData.Type.push(authorityadmin[0].Type);
        //   var idList = authorityadmin[0].ID;
        //   // console.log(obj[i])
        //   if (!obj[i].hasChildren) {
        //     currentData.ID.push(obj[i].value)
        //   }
        //   // console.log(idList)
        // }
        // console.log(currentData)
        // // var testData = {
        // //   Type: '',
        // //   ID: []
        // // }
        // this.currentData = currentData.ID
        // for (var s = 0; s < currentData.length; s++) {
        // testData.Type = currentData.Type[0];
        // testData.ID = currentData.ID;
        // // }
        // this.checkData = testData;
      },
      // 查询操作
      query() {
        this.currentPage = 1;
        // const obj = this.$refs['demoCascader'].getCheckedNodes()
        // console.log(obj)
        // var testDate = [];
        // for (var i = 0; i < obj.length; i++) {
        //   if (!obj[i].hasChildren) {
        //     console.log(obj[i].value)
        //     testDate.push(obj[i].value);
        //   }
        // }
        // this.currentData = testDate;
         if(this.organizationValue.length==0) {
          this.currentData = this.standardData;
        }
        this.getEquipmentInforStopandbyAnalysisList();
        this.getEquipmentInforStopAnalysisBarByMonth();
        this.getEquipmentInforStopAnalysisBarByDay();
        this.getEquipmentInforStopAnalysisPieChartCountBystandByCause();
        this.getEquipmentInforStopAnalysisPieChartSumBystandByCause()
      },
      //停机分析表
      async getEquipmentInforStopandbyAnalysisList() {
        var param = {
          pageSize: this.pageSize,
          pageNumber: this.currentPage,
          // equipmentlevelID: 1,
          // date: new Date().getTime(),
          organization: {
            EquipmentID: this.currentData,
            // levelID: [1],
            // CategoryID: [1, 2],
          }
        }
        if (this.dataTime != '' && this.dataTime != null) {
          param.date = this.dataTime;
        }
        if (this.equipmentUseValue.join(',') != '') {
          param.organization.CategoryID = this.equipmentUseValue; //用途
        }
        if (this.importanceListValue.join(',') != '') {
          param.organization.levelID = this.importanceListValue; //重要程度
        }
        const selectRes = await getEquipmentInforStopAnalysisList(param);
        this.tableData = selectRes.data.rows;
        this.pageQuantity = selectRes.data.pageQuantity;
      },
      handleCurrentChange(val) {
        // this.currentPage = val;
        this.getEquipmentInforStopandbyAnalysisList(); //查询table数据的方法
      },
      //停机分析柱状图月
      async getEquipmentInforStopAnalysisBarByMonth() {
        var param = {
          // date: new Date().getTime(),
          organization: {
            EquipmentID: this.currentData,
            // levelID: [1],
            // CategoryID: [1, 2],
          }
        }
        if (this.dataTime != '' && this.dataTime != null) {
          param.date = this.dataTime;
        }
        if (this.equipmentUseValue.join(',') != '') {
          param.organization.CategoryID = this.equipmentUseValue; //用途
        }
        if (this.importanceListValue.join(',') != '') {
          param.organization.levelID = this.importanceListValue; //重要程度
        }
        const selectRes = await getEquipmentInforStopAnalysisBarByMonth(param);
        if (selectRes.data != {}) {
          this.barMonth.xAxis = selectRes.data.axis
          this.barMonth.yAxis = selectRes.data.list
        }

        this.drawShape();
      },
      //停机分析柱状日图
      async getEquipmentInforStopAnalysisBarByDay() {
        var param = {
          organization: {
            EquipmentID: this.currentData,
            // levelID: [1],
            // CategoryID: [1, 2],
          }
        }
        if (this.dataTime != '' && this.dataTime != null) {
          param.date = this.dataTime;
        }
        if (this.equipmentUseValue.join(',') != '') {
          param.organization.CategoryID = this.equipmentUseValue; //用途
        }
        if (this.importanceListValue.join(',') != '') {
          param.organization.levelID = this.importanceListValue; //重要程度
        }
        const selectRes = await getEquipmentInforStopAnalysisBarByDay(param);
        if (selectRes.data != {}) {
          this.BarByDay.xAxis = selectRes.data.axis
          this.BarByDay.yAxis = selectRes.data.list
        }

        this.drawShape();
      },
      //停机分析饼状图次数
      async getEquipmentInforStopAnalysisPieChartCountBystandByCause() {
        var param = {
          organization: {
            EquipmentID: this.currentData,
            // levelID: [1],
            // CategoryID: [1, 2],
          }
        }
        if (this.dataTime != '' && this.dataTime != null) {
          param.date = this.dataTime;
        }
        if (this.equipmentUseValue.join(',') != '') {
          param.organization.CategoryID = this.equipmentUseValue; //用途
        }
        if (this.importanceListValue.join(',') != '') {
          param.organization.levelID = this.importanceListValue; //重要程度
        }
        const selectRes = await getEquipmentInforStopAnalysisPieChartCountBystandByCause(param);
        console.log(selectRes.data)
        this.dataPieCount.data = selectRes.data.data
        this.dataPieCount.total = selectRes.data.total
        this.drawShape();
      },

      //停机分析饼状图
      async getEquipmentInforStopAnalysisPieChartSumBystandByCause() {
        var param = {
          organization: {
            EquipmentID: this.currentData,
            // levelID: [1],
            // CategoryID: [1, 2],
          }
        }
        if (this.dataTime != '' && this.dataTime != null) {
          param.date = this.dataTime;
        }
        if (this.equipmentUseValue.join(',') != '') {
          param.organization.CategoryID = this.equipmentUseValue; //用途
        }
        if (this.importanceListValue.join(',') != '') {
          param.organization.levelID = this.importanceListValue; //重要程度
        }
        const selectRes = await getEquipmentInforStopAnalysisPieChartSumBystandByCause(param);
        console.log(selectRes.data)
        this.dataPieCount1.data = selectRes.data.data
        this.dataPieCount1.total = selectRes.data.total
        this.drawShape();
      },


      drawShape() {
        this.chartColumn = echarts.init(document.getElementById("chartColumn1"));
        this.chartColumn.setOption({
          tooltip: {
            trigger: 'item',
          },
          color: ['#5B8FF9'],
          xAxis: {
            type: "category",
            data: this.barMonth.xAxis,
          },
          yAxis: {
            type: "value",
          },
          series: [{
            data: this.barMonth.yAxis,
            type: "bar",
            barWidth: '50px'
          }, ],
        });
        this.chartColumn = echarts.init(document.getElementById("chartColumn2"));
        this.chartColumn.setOption({
          tooltip: {
            trigger: 'item',
          },
          xAxis: {
            type: "category",
            data: this.BarByDay.xAxis,
          },
          yAxis: {
            type: "value",
          },
          series: [{
            data: this.BarByDay.yAxis,
            type: "bar",
            barWidth: '30px',
            itemStyle: {
              color: "#FF0D0D",
            },
          }, ],
        });
        // 报警信息 

        this.chartColumn = echarts.init(document.getElementById("chartColumn3"));
        this.chartColumn.setOption({
          tooltip: {
            trigger: 'item',
            formatter: '{b} : {c} {d}% '
            // {a}（系列名称），{b}（数据项名称），{c}（数值）, {d}（百分比）
          },
          title: {
            text: this.dataPieCount1.total,
            subtext: '时长',
            left: 'center',
            top: '34%',
            textStyle: { //主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
              fontSize: 30,
            },
            subtextStyle: { //副标题文本样式{"color": "#aaa"}
              fontSize: 16,
            },
          },
          color: ['#E86B5A', '#6294F8', '#61D9AA', '#647697', '#F5BF21', '#6DC8EC'],
          // graphic: { //图形中间文字
          //   type: "text",
          //   left: "center",
          //   top: 130,
          //   style: {
          //     text: this.dataPieCount1.total,
          //     // textAlign: "center",
          //     fill: "#000000",
          //     fontSize: 36,
          //   }
          // },
          legend: {
            bottom: '10%',
            left: 'center',
            icon: 'circle',
            textStyle: {
              color: '#808080',
            }
          },
          series: [{
            name: '',
            type: 'pie',
            radius: ['40%', '55%'],
            center: ['50%', '40%'],
            label: {
              // formatter: '{a}：{d}%',
              formatter: '{d}%',
            },
            data: this.dataPieCount1.data,
          }, ],
        });
        this.chartColumn = echarts.init(document.getElementById("chartColumn4"));
        this.chartColumn.setOption({
          tooltip: {
            trigger: 'item',
            formatter: '{b} : {c} {d}% '
          },
          color: ['#E86B5A', '#6294F8', '#61D9AA', '#647697', '#F5BF21', '#6DC8EC'],
          title: {
            text: this.dataPieCount.total,
            subtext: '次数',
            left: 'center',
            top: '34%',
            textStyle: { //主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
              fontSize: 30,
            },
            subtextStyle: { //副标题文本样式{"color": "#aaa"}
              fontSize: 16,
            },
          },
          // graphic: { //图形中间文字
          //   type: "text",
          //   left: "center",
          //   top: 130,
          //   style: {
          //     text: this.dataPieCount.total,
          //     textAlign: "center",
          //     fill: "#000000",
          //     fontSize: 36,
          //   }
          // },
          legend: {
            bottom: '10%',
            left: 'center',
            icon: 'circle',
            textStyle: {
              color: '#808080',
            }
          },
          series: [{
            name: '',
            type: 'pie',
            radius: ['40%', '55%'],
            center: ['50%', '40%'],
            label: {
              // formatter: '{a}：{d}%',
              formatter: '{d}%',
            },
            data: this.dataPieCount.data,
          }]
        })

      },
      formatDate(row, column) {
        // 获取单元格数据
        let data = row[column.property]
        if (data == undefined) {
          return ''
        };
        return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
      },
    }
  }
</script>

<style scoped>
  .aa {
    text-align: left;
    padding: 10px 0 0 32px;

  }

  .aa>p {
    display: inline;
  }

  .aaRed {
    color: red;
  }

  .aaOrange {
    color: orange;
  }

  .aaGreen {
    color: green;
  }

  .alarmAnalysisHeader {
    width: 1862px;
    height: 40px;
    padding-left: 20px;
    background-color: #e2f0fe;
    border: 1px solid #ccdbe5;
    font-size: 16px;
    font-family: Source Han Sans SC;
  }

  .dimensionTextip {
    width: 400px;
    height: 98px;
    margin-right: 68px;
  }

  /* .el-radio-button__inner {
    width: 140px;
    height: 36px;
    font-size: 16px;
    margin-right: 10px;
    background-color: #e6e6e6;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .el-radio-button:last-child .el-radio-button__inner {
    border-radius: 0;
  }

  .el-radio-button:first-child .el-radio-button__inner {
    border-radius: 0;
  } */

  .dimensionTrendSelect {
    width: 160px;
    height: 36px;
  }

  .dimensionTrendSelect2 {
    width: 150px;
    height: 36px;
  }

  .diy_div {
    /* width: 1831px; */
    height: 450px;
    padding: 0px;
  }

  .dimensionTrendSelect2 {
    width: 150px;
    height: 36px;
  }

  .dimensionSelectButton {
    width: 110px;
    height: 36px;
    background: #009bfd;
  }

  .alarmAnalysisMargin {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 20px;
  }

  /* button {
    padding-top: 0;
    padding-bottom: 0;
  } */
</style>

<style lang="less" scoped>
</style>